<script>
import {messagesAiComputed, messageMethods, profileMethods} from "@/state/helpers";
import NavBarLeft from "@/components/NavBarLeft";
import RoomsChatList from "@/components/RoomsChatList";
import {pixelsMethods} from "@/state/helpers";

export default {
	name: "index.vue",
	components: {
		chatIfNoUsers: () => import("./chatIfNoUsers"),
		Loader: () => import("@/components/Partials/Loader.vue"),
		RoomChat: () => import("@/components/RoomChat"),
    	RoomEmpty: () => import("@/components/RoomEmpty"),
		NavBarLeft,
		RoomsChatList,
	},
	props: {
		responsiveBreakpoint: {
			type: Number,
			default: 900,
		},
	},
	data() {
		return {
			isMobile: false,
			showRoomsList: true,
		}
	},
	computed: {
		...messagesAiComputed,
		selectedRoomChat() {
			if (this.roomsList) {
				return this.roomsList.find(item => item.id === this.activeRoomId)
			} else {
				return null
			}
		},
	},
	methods: {
		...messageMethods,
		...profileMethods,
		...pixelsMethods,
		updateResponsive() {
			this.isMobile = window.innerWidth < this.responsiveBreakpoint;
		},
		fetchRoom(room) {
			if(room) {
				this.$store.dispatch('messageState/getSingleRoomMessages', room.item.id)
					.then(()=>{
						let userChat = document.getElementsByClassName("user-chat");
						if (userChat) {
							userChat[0]?.classList.add("user-chat-show");
						}
					})
			}
		},
	},
	mounted() {
		this.updateResponsive();
		window.addEventListener("resize", (ev) => {
			if (ev.isTrusted) this.updateResponsive();
		});

		this.$store.dispatch('messageState/getRoomsList');
		this.$store.dispatch('profile/getUserInfo');
		this.sendPixelEvent('pageview');
	},
}
</script>

<template>
	<div class="layout-wrapper d-lg-flex">
		<NavBarLeft/>
		<Loader v-if="loading" />
		<template v-else>
			<chatIfNoUsers v-if="!roomsList.length" />
			<template v-else>
				<!-- start chat-leftsidebar -->
				<div class="chat-leftsidebar mr-lg-1">
					<div class="tab-content">
						<!-- Start chats tab-pane -->
						<div
							class="tab-pane fade show active"
							id="pills-chat"
							role="tabpanel"
							aria-labelledby="pills-chat-tab"
						>
							<RoomsChatList
								:chatRoomsList="roomsList"
								@fetchRoom="fetchRoom"
							/>
						</div>
					</div>
				</div>

				<RoomChat
					v-if="selectedRoomChat"
					:chatRoom="selectedRoomChat"
					:currentChatRoomId="selectedRoomChat.id"
				/>
				<RoomEmpty v-else />
			</template>
		</template>
	</div>
</template>
