<script>
export default {
	name: "ChatLimitMessage",
	methods: {
		goToCheckout() {
			window.location.href = '/checkout';
		}
	}
}
</script>

<template>
	<div>
		<h5 class="text-center text-truncate">Ready for more сhats?</h5>
		<b-alert show variant="danger">Upgrade now for just $1 to unlock an extra chat for 24 hours! Or, go big with a monthly subscription for unlimited chats.
			Elevate your experience today!</b-alert>
		<div class="text-right pt-3 mt-3">
			<b-button variant="link" @click="$emit('close')">{{
					$t("chat.tabs.chats.modal.form.buttons.close")
				}}
			</b-button>
			<b-button type="submit" variant="primary" class="ml-1" @click="goToCheckout">Continue</b-button>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>