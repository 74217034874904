<script>
import simplebar from "simplebar-vue";
import {profileComputed, messagesAiComputed} from "@/state/helpers";
import CreateNewChatForm from "@/components/Partials/CreateNewChatForm";
import ChatLimitMessage from "@/components/Partials/ChatLimitMessage";
export default {
	name: "RoomsChatList",
	components: {
		simplebar,
		CreateNewChatForm,
		ChatLimitMessage
	},
	props: {
		chatRoomsList: Array
	},
	data() {
		return {
			selectedRoomId: ''
		}
	},
	computed: {
		...messagesAiComputed,
		...profileComputed
	},
	methods: {
		openRoom(item)  {
			this.selectedRoomId = item.id;
			this.$store.state.activeRoomId = item.id;
			this.$emit("fetchRoom", { item });
			if(document.getElementById("profile-show")?.style) {
				document.getElementById("profile-show").style.display = "none";
			}
      // let userChat = document.getElementsByClassName("user-chat");
      // if (userChat) {
      //   userChat[0].classList.add("user-chat-show");
      // }
		}
	},
	mounted() {
		if(this.chatRoomsList.length){
			this.openRoom(this.chatRoomsList[0])
		}
	}
}
</script>

<template>
	<div class="px-4 pt-4">
		<div class="mb-4 d-flex align-items-center justify-content-between">
			<h4>{{ $t("chat.tabs.chats.title") }}</h4>
			<button
				type="button"
				class="btn nav-btn add-chat-icon"
				@click="$bvModal.show('addNewChatModal')"
			>
				<i class="ri-add-circle-line"></i>
			</button>
			<!-- add Chat Modal -->
			<b-modal id="addNewChatModal" centered hide-header hide-footer>
				<ChatLimitMessage v-if="!userInfo.can_create_chat" @close="$bvModal.hide('addNewChatModal')" />
				<div v-else class="p-4">
					<h5 class="text-center text-truncate">Create New Chat</h5>
					<CreateNewChatForm @close="$bvModal.hide('addNewChatModal')" />
				</div>
			</b-modal>
			<!-- add Chat Modal -->
		</div>
		<hr />
		<div class="px-2 position-relative">
			<h5 class="mb-3 px-3 font-size-16">
				{{ $t("chat.tabs.chats.recent.title") }}
			</h5>
			<div v-if="loadingMessages" id="load-recent-chats">
				<div class="snippet" data-title="dot-flashing">
					<div class="stage">
						<div class="dot-flashing"></div>
					</div>
				</div>
			</div>
			<simplebar class="chat-message-list" id="chat-list" ref="current">
				<ul class="list-unstyled chat-list chat-user-list">
					<li
						v-for="(item, index) in chatRoomsList"
						:key="index"
						:class="activeRoomId === item.id ? 'active' : ''"
						@click="openRoom(item)"
					>
						<div class="room-link">
							<div class="media">
								<div class="chat-user-img online align-self-center mr-3">
									<img v-if="item.image" :src="item.image" class="avatar-sm image-ava-single" alt="">
									<div v-else class="avatar-sm">
										<span class="avatar-title rounded-circle bg-soft-primary text-primary">
											{{ (item.name).charAt(0) }}
										</span>
									</div>
								</div>

								<div class="media-body overflow-hidden">
									<h5 class="text-truncate font-size-15 mb-1">
										{{ (item.name) }}
										<i
											:class="item.is_free_chat ? 'text-success' : 'text-danger'"
											class="ri-record-circle-fill font-size-10 d-inline-block ml-1"
										></i>
									</h5>
									<p class="chat-user-message text-truncate mb-0">
										{{ (item.city) }}
									</p>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</simplebar>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>